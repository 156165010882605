/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Card, Grid } from "@mui/material";
import FlexBox from "components/FlexBox";
// eslint-disable-next-line
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { FC, useEffect, useState } from "react";
import { default as CollapsibleTable } from "./OrderTable";
// import { API } from "../../service/api";
import CachedTwoToneIcon from "@mui/icons-material/CachedTwoTone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoIcon from "@mui/icons-material/Info";
import LocalHospitalTwoToneIcon from "@mui/icons-material/LocalHospitalTwoTone";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import TuneIcon from "@mui/icons-material/Tune";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import "./orderStatus.css";

interface Status {
  search: string;
  allOrders: any;
  setAllOrders: any;
}
const useStyles = makeStyles({
  grid: {
    // margin: 2
  },
  boxColor: {
    backgroundColor: "#3FA436",
  },
});

const filterArray = [
  {
    id: 1,
    text: "Delivered",
    icon: <LocalShippingTwoToneIcon style={{ fontSize: "15px" }} />,
  },
  {
    id: 2,
    text: "Cancelled",
    icon: <LocalHospitalTwoToneIcon style={{ fontSize: "15px" }} />,
  },
  {
    id: 3,
    text: "Failed",
    icon: <InfoIcon style={{ fontSize: "15px" }} />,
  },
  {
    id: 4,
    text: "Returned",
    icon: <CachedTwoToneIcon style={{ fontSize: "15px" }} />,
  },
  {
    id: 5,
    text: "Rejected",
    icon: <LocalHospitalTwoToneIcon style={{ fontSize: "15px" }} />,
  },
];

const OrderStatus: FC<Status> = (props): JSX.Element => {
  const [value, setValue] = useState("Incoming");
  const [filtered, setFiltered] = useState(0);
  const [incomingOrderCount, setIncomingorderCount] = useState(0);
  const [packingOrderCount, setPackingOrderCount] = useState(0);
  const [readyOrderCount, setReadyOrderCount] = useState(0);
  const [shippingOrderCount, setShippingOrderCount] = useState(0);
  const [deliveredOrderCount, setDeliveredOrderCount] = useState(0);
  const [totalCollected, setTotalCollected] = useState(0);
  const [deliveredData, setDeliveredData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  // const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [unpaidModalOpen, setUnpaidModalOpen] = useState(false);
  const [totalDeliveredAmount, setTotalDeliveredAmount] = useState(0);

  const classes = useStyles();
  const [drop, setDrop] = useState(false);
  const [deliveryType, setDeliveryType] = useState(props.search);
  const handleFilters = () => {
    if (drop) {
      setDrop(false);
    } else {
      setDrop(true);
    }
  };
  const [calendar, setCalendar] = useState(false);
  const [earn, setEarn] = useState(false);
  const earnReport = {
    height: "35rem",
    width: "40rem",
    backgroundColor: "white",
    zIndex: "1",
    position: "fixed",
    top: "5rem",
    left: "35rem",
    padding: "1.5%",
    paddingTop: "0",
  };
  const income = () => {
    if (earn) {
      setEarn(false);
    } else {
      setEarn(true);
    }
  };
  const timely = {
    backgroundColor: "white",
    margin: "5px 5px",
    border: "none",
    height: "1rem",
    width: "10px",

    boxShadow: "1px 2px 8px #8f8d8e",
  };
  const timeTaken = () => {
    if (calendar) {
      setCalendar(false);
    } else {
      setCalendar(true);
    }
  };
  const [checked, setChecked] = useState(false);
  const handleDrop = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    // setChecked(event.target.checked);
    setFiltered(i);
  };
  type MoneyCollected = {
    receivedAmount?: number;
    paymentMethod?: string;
    acceptorId?: string;
  };

  type Order = {
    _id: string;
    createdAt: Date;
    buyerDetails: {
      name: string;
    };
    rider: {
      name: string;
      contact: string;
    };
    moneyCollected?: MoneyCollected[];
    order: {
      totalAmt?: number;
    };
  };
  const exportToCSV = (data: any, headers: any) => {
    if (!data.length) {
      alert("No data to export");
      return;
    }

    const csvRows = [
      headers.map((col: any) => col.title).join(","), // Create the header row from the titles
    ];

    // Map over the data to create CSV rows
    data.forEach((row: any) => {
      const csvRow = headers.map((col: any) => {
        const cellValue = row[col.key];
        if (cellValue === null || cellValue === undefined) return "";
        return `"${cellValue.toString().replace(/"/g, '""')}"`; // Handle data and escape quotes
      });
      csvRows.push(csvRow.join(","));
    });

    // Blob for the CSV data and trigger download
    const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "exported_data.csv";
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  };

  type DeliveredDataModalProps = {
    open: boolean;
    onClose: () => void;
    deliveredData: Order[];
  };

  const DeliveredDataModal = (props: DeliveredDataModalProps) => {
    const { open, onClose, deliveredData } = props;
    const [filter, setFilter] = useState<string>("");

    const handleFilterChange = (event: any) => {
      setFilter(event.target.value as string);
    };

    const filteredData = filter
      ? deliveredData.filter(
          (order) =>
            order.moneyCollected &&
            order.moneyCollected[0]?.paymentMethod === filter
        )
      : deliveredData;

    const totalAmount = filteredData.reduce((total, order) => {
      if (order.moneyCollected && Array.isArray(order.moneyCollected)) {
        const orderTotal = order.moneyCollected.reduce((orderSum, payment) => {
          return orderSum + (payment.receivedAmount || 0);
        }, 0);
        return total + orderTotal;
      }
      return total;
    }, 0);
    const tableHeaders = [
      { title: "Order ID", key: "_id" },
      { title: "Date Time", key: "createdAt" },
      { title: "Buyer Name", key: "buyerDetails.name" },
      { title: "Rider Name", key: "rider.name" },
      { title: "Rider Contact", key: "rider.contact" },
      { title: "Collected Amount", key: "moneyCollected.receivedAmount" },
      { title: "Acceptor Name", key: "moneyCollected.acceptorId" },
      { title: "Payment Method", key: "moneyCollected.paymentMethod" },
    ];
    const processDataForExport = (data: any) => {
      return data.reduce((acc: any, order: any) => {
        if (order.moneyCollected) {
          order.moneyCollected.forEach((payment: any, index: any) => {
            acc.push({
              _id: order._id,
              createdAt: order.createdAt,
              "buyerDetails.name": order.buyerDetails.name,
              "rider.name": order.rider?.name,
              "rider.contact": order.rider?.contact,
              "moneyCollected.receivedAmount": payment.receivedAmount,
              "moneyCollected.acceptorId": payment.acceptorId,
              "moneyCollected.paymentMethod": payment.paymentMethod,
            });
          });
        }
        return acc;
      }, []);
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            marginTop: "10%",
            maxWidth: "80%",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Payment Method</InputLabel>
            <Select value={filter} onChange={handleFilterChange}>
              <MenuItem value=''>
                <em>All</em>
              </MenuItem>
              <MenuItem value='Cash'>Cash</MenuItem>
              <MenuItem value='Cheque'>Cheque</MenuItem>
              <MenuItem value='Company QR'>Company QR</MenuItem>
              <MenuItem value='Rider to Company QR'>
                Rider to Company QR
              </MenuItem>
              <MenuItem value='NEFT/RTGS/IMPS'>NEFT/RTGS/IMPS</MenuItem>
              {/* Add other payment methods here */}
            </Select>
          </FormControl>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Date Time</TableCell>
                <TableCell>Buyer Name</TableCell>
                <TableCell>Rider Name</TableCell>
                <TableCell>Rider Contact</TableCell>
                <TableCell>Collected Amount</TableCell>
                <TableCell>Acceptor Name</TableCell>
                <TableCell>Payment Method</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map(
                (order) =>
                  order.moneyCollected &&
                  order.moneyCollected.map((payment, index) => (
                    <TableRow key={order._id + "-" + index}>
                      {" "}
                      {/* Unique key for each row */}
                      <TableCell>{order._id}</TableCell>
                      <TableCell>{order.createdAt}</TableCell>
                      <TableCell>{order.buyerDetails.name}</TableCell>
                      <TableCell>{order.rider?.name}</TableCell>
                      <TableCell>{order.rider?.contact}</TableCell>
                      <TableCell>{payment.receivedAmount}</TableCell>
                      <TableCell>{payment.acceptorId}</TableCell>
                      <TableCell>{payment.paymentMethod}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>

          <Box sx={{ marginTop: 2, fontWeight: "bold" }}>
            Total Amount: {totalAmount}
          </Box>
          <Button
            onClick={() =>
              exportToCSV(processDataForExport(filteredData), tableHeaders)
            }
            variant='contained'
            color='primary'
            sx={{ mt: 2 }}
          >
            Export to CSV
          </Button>
        </Box>
      </Modal>
    );
  };
  type UNPaidDeliveredDataModalProps = {
    open: boolean;
    onClose: () => void;
    deliveredData: Order[];
  };

  const UNPaidDeliveredDataModal = (props: UNPaidDeliveredDataModalProps) => {
    const { open, onClose, deliveredData } = props;
    const [filter, setFilter] = useState<string>("");

    const handleFilterChange = (event: any) => {
      setFilter(event.target.value as string);
    };

    const uniqueRiders = Array.from(
      new Set(
        deliveredData
          .filter(
            (order) =>
              !order.moneyCollected || order.moneyCollected.length === 0
          ) // Filter orders with no money collected
          .map((order) => order.rider?.name)
          .filter((name) => name) // Ensure the rider's name exists
      )
    );
    // Filter data by rider name when there is no payment or money collected details are empty
    const filteredData = deliveredData.filter(
      (order) =>
        (!order.moneyCollected || !order.moneyCollected[0]) &&
        (filter === "" || order.rider?.name === filter)
    );

    // Calculate the total amount from filtered data where payments are available
    const totalAmount = filteredData.reduce((total, order) => {
      return total + (order.order.totalAmt || 0);
    }, 0);
    const tableHeaders = [
      { title: "Order ID", key: "_id" },
      { title: "Date Time", key: "createdAt" },
      { title: "Buyer Name", key: "buyerDetails.name" },
      { title: "Rider Name", key: "rider.name" },
      { title: "Rider Contact", key: "rider.contact" },
      { title: "Order Amount", key: "order.order.totalAmt" },
    ];
    const processDataForExport = (data: any) => {
      return data.map((order: any) => ({
        _id: order._id,
        createdAt: order.createdAt,
        "buyerDetails.name": order.buyerDetails?.name,
        "rider.name": order.rider?.name,
        "rider.contact": order.rider?.contact,
        "order.order.totalAmt": order.order?.totalAmt, // Ensure safe navigation with ?
      }));
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            marginTop: "10%",
            maxWidth: "80%",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <FormControl fullWidth>
            <InputLabel>Rider Name</InputLabel>
            <Select value={filter} onChange={handleFilterChange}>
              <MenuItem value=''>
                <em>All Riders</em>
              </MenuItem>
              {uniqueRiders.map((riderName) => (
                <MenuItem key={riderName} value={riderName}>
                  {riderName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Date Time</TableCell>
                <TableCell>Buyer Name</TableCell>
                <TableCell>Rider Name</TableCell>
                <TableCell>Rider Contact</TableCell>
                <TableCell>Order Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((order) => (
                <TableRow key={order._id}>
                  <TableCell>{order._id}</TableCell>
                  <TableCell>{order.createdAt}</TableCell>
                  <TableCell>{order.buyerDetails.name}</TableCell>
                  <TableCell>{order.rider?.name}</TableCell>
                  <TableCell>{order.rider?.contact}</TableCell>
                  <TableCell>{order.order.totalAmt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box sx={{ marginTop: 2, fontWeight: "bold" }}>
            Total Amount: {totalAmount.toLocaleString()}
          </Box>
          <Button
            onClick={() =>
              exportToCSV(processDataForExport(filteredData), tableHeaders)
            }
            variant='contained'
            color='primary'
            sx={{ mt: 2 }}
          >
            Export to CSV
          </Button>
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    // !props.allOrders && (value === "All Orders") && setValue("Incoming");
    value === "All Orders" && props.setAllOrders("open");
    if (props.allOrders === "back") {
      setValue("Incoming");
      props.setAllOrders("close");
    }
  }, [value, props.allOrders]);
  return (
    <>
      <Card sx={{ backgroundColor: " #f3f4f9" }}>
        <Box sx={{ ...earnReport, display: earn ? "block" : "none" }}>
          <h1>Earnings Report</h1>
          <hr />
          <table style={{ width: "80%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th>Head</th>
                <th>Value</th>
              </tr>

              <tr>
                <TableCell>Total transactions</TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell>Average Order Value</TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell>Transaction value</TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell>Gateway charge</TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell>Commission</TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell>Insurance</TableCell>
                <TableCell></TableCell>
              </tr>
              <tr>
                <TableCell>Net Settlement</TableCell>
                <TableCell></TableCell>
              </tr>
            </thead>
          </table>
          <button
            style={{
              backgroundColor: "#1F1F2B",
              position: "fixed",
              marginLeft: "40em",
              color: "white",
              height: "30px",
            }}
            onClick={() => setEarn(false)}
          >
            OK
          </button>
        </Box>

        <Box>
          {value === "All Orders" ? (
            <FlexBox
              style={{
                margin: "5px 10px 5px 10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <FlexBox justifyContent="space-between"> */}
              <FlexBox>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button sx={timely}>1H</Button>
                    <Button sx={timely}>2H</Button>
                    <Button sx={timely}>3H</Button>
                    <Button sx={timely}>1W</Button>
                    <Button sx={timely}>1M</Button>

                    <div>
                      <CalendarMonthIcon
                        onClick={() => timeTaken()}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </div>
                    <input
                      type='date'
                      style={{ display: calendar ? "block" : "none" }}
                    />
                  </div>
                  {/* <DatePicker
                    sx={{ display: calendar ? "block" : "none" }}
                    label="Basic example"
                    value={date}
                    onChange={(newDate: any) => {
                      onChange(newDate);
                    }}
                  /> */}
                </div>
                <Button
                  onClick={income}
                  sx={{
                    bgcolor: "#F2F2F2",
                    margin: "5px 5px",
                    padding: "5px",
                    borderRadius: "12px",
                    fontSize: "12px",
                    boxShadow: "1px 2px 8px #8f8d8e",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  Earnings Report
                </Button>
              </FlexBox>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {filtered > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      alignItems: "center",
                      padding: "2px 7px",
                      borderRadius: "10px",
                      bgcolor: "#3366ff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {filterArray[filtered - 1].icon}
                    </Box>
                    {filterArray[filtered - 1].text}
                  </Box>
                )}

                <FlexBox
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "2px 5px",
                    border: "2px solid yellow",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    padding: "3px 35px",
                    cursor: "pointer",
                  }}
                  onClick={handleFilters}
                >
                  {drop && (
                    <Box
                      sx={{
                        position: "absolute",
                        fontSize: "13px",
                        marginTop: "20px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        marginLeft: "-36px",
                        zIndex: "4",
                        bgcolor: "white",
                        // borderRadius: "40px",
                      }}
                    >
                      {filterArray.map((el, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "2px 7px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                checked={
                                  el.id === filtered ? !checked : checked
                                }
                                onChange={(e) => handleDrop(e, el.id)}
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 15,
                                    color: "GrayText",
                                  },
                                }}
                              />
                              {el.text}
                            </Box>
                            {el.icon}
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  <div
                    onClick={() => handleFilters()}
                    style={{ alignItems: "center", cursor: "pointer" }}
                  >
                    Filter
                    <TuneIcon style={{ fontSize: "18px" }} />
                  </div>
                </FlexBox>
                <div>
                  {/* <Chip
                    style={{ fontSize: "1.2em", fontWeight: "bolder" }}
                    label='All Orders'
                    color={value === "All Orders" ? "warning" : "primary"}
                    onClick={() => setValue("All Orders")}
                  /> */}
                </div>
              </div>
            </FlexBox>
          ) : (
            <FlexBox
              flexDirection='column'
              justifyContent='space-between'
              padding='1rem'
              alignItems='center'
            >
              <Stack direction='row' spacing={2}>
                <h3>
                  Total Collected Cash: {totalCollected?.toFixed(2)} INR/{" "}
                  {totalDeliveredAmount?.toFixed(2)}
                </h3>
                <Button onClick={() => setModalOpen(true)}>
                  Show Payment Data
                </Button>
                <DeliveredDataModal
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  deliveredData={deliveredData}
                />
                <Button onClick={() => setUnpaidModalOpen(true)}>
                  Show unpaid Data
                </Button>
                <UNPaidDeliveredDataModal
                  open={unpaidModalOpen}
                  onClose={() => setUnpaidModalOpen(false)}
                  deliveredData={deliveredData}
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                <Chip
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    borderRadius: "5%",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: value === "Incoming" ? "white" : "#045ba0",
                    color: value === "Incoming" ? "#045ba0" : "white",
                  }}
                  label={`Incoming${
                    incomingOrderCount ? ` ( ${incomingOrderCount} )` : ""
                  }`}
                  // color={value === "Incoming" ? "primary" : "warning"}
                  onClick={() => setValue("Incoming")}
                />
                <Chip
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    borderRadius: "5%",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: value === "Packing" ? "white" : "#045ba0",
                    color: value === "Packing" ? "#045ba0" : "white",
                  }}
                  label={`Packing${
                    packingOrderCount ? ` ( ${packingOrderCount} )` : ""
                  }`}
                  // color={value === "Packing" ? "primary" : "warning"}

                  onClick={() => setValue("Packing")}
                />
                <Chip
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    borderRadius: "5%",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: value === "Ready" ? "white" : "#045ba0",
                    color: value === "Ready" ? "#045ba0" : "white",
                  }}
                  label={`Ready${
                    readyOrderCount ? ` ( ${readyOrderCount} )` : ""
                  }`}
                  // color={value === "Ready" ? "primary" : "warning"}
                  onClick={() => setValue("Ready")}
                />
                <Chip
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    borderRadius: "5%",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: value === "Shipping" ? "white" : "#045ba0",
                    color: value === "Shipping" ? "#045ba0" : "white",
                  }}
                  label={`Shipping${
                    shippingOrderCount ? ` ( ${shippingOrderCount} )` : ""
                  }`}
                  // color={value === "Shipping" ? "info" : "warning"}
                  onClick={() => setValue("Shipping")}
                />
                <Chip
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    borderRadius: "5%",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor:
                      value === "Delivered" ? "white" : "#045ba0",
                    color: value === "Delivered" ? "#045ba0" : "white",
                  }}
                  label={`Delivered${
                    deliveredOrderCount ? ` ( ${deliveredOrderCount} )` : ""
                  }`}
                  onClick={() => setValue("Delivered")}
                />
              </Stack>
              <Stack direction='row' spacing={2}>
                {/* <Chip
                  style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    borderRadius: "5%",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                  label='All Orders'
                  color={value === "All Orders" ? "info" : "warning"}
                  onClick={() => setValue("All Orders")}
                /> */}
              </Stack>
            </FlexBox>
          )}
        </Box>
        <Grid container spacing={2} my={2} px={2} className={classes.grid}>
          <CollapsibleTable
            deliveryType={deliveryType}
            type={value}
            setIncomingorderCount={setIncomingorderCount}
            setPackingOrderCount={setPackingOrderCount}
            setReadyOrderCount={setReadyOrderCount}
            setShippingOrderCount={setShippingOrderCount}
            setDeliveredOrderCount={setDeliveredOrderCount}
            setTotalCollected={setTotalCollected}
            setTotalDeliveredAmount={setTotalDeliveredAmount}
            setDeliveredData={setDeliveredData}
          />
        </Grid>
      </Card>
    </>
  );
};

export default OrderStatus;
